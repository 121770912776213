<template>
  <div class="flex flex-col sm:flex-row justify-between sm:h-96">
    <SeriesPreview
      v-for="preview in previews"
      :key="preview.name"
      class="series-preview cursor-pointer mx-auto sm:mx-0"
      v-bind:seriesData="preview"
    ></SeriesPreview>
  </div>
</template>

<script>
import SeriesPreview from "@/components/Series/SeriesPreview.vue";

export default {
  name: "SeriesView",
  data: function () {
    return {
      previews: this.$store.getters.getAllSeries,
    };
  },
  components: {
    SeriesPreview,
  },
};
</script>

<style scoped lang="postcss">
.series-preview {
  max-width: 16rem;
}
</style>
