<template>
  <!--  change to params-->
  <router-link
    :to="{
      path: '/serien/' + seriesData.name,
    }"
  >
    <div class="flex flex-col items-center h-full relative">
      <div class="sm:h-80 relative sm:flex">
        <img class="m-auto max-h-72" :src="imgUrl" alt="" />
      </div>
      <h1 class="sub-header grey-text sm:absolute mt-4 sm:mt-0 mb-12 sm:mb-0 bottom-0 sm:whitespace-nowrap text-center">
        {{ seriesData.name }}
      </h1>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "SeriesPreview",
  props: {
    seriesData: {
      type: Object,
    },
  },
  data: function() {
    return {
      imgUrl: require(`@/assets/img/${this.seriesData.img}`),
    };
  },
};
</script>

<style scoped lang="postcss"></style>
